import { graphql } from 'gatsby'
import { Hero } from '@components/Hero'
import { Link } from '@components/Link'
import {
  Flex,
  Text,
  Box,
  Heading,
  SimpleGrid,
  Image,
  useBreakpoint,
} from '@chakra-ui/react'
import { SectionContainer } from '@components/SectionContainer'

const WhoWeArePage = ({ data }) => {
  const { hero, features, partners } = data.whoWeAreJson
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  return (
    <>
      <SectionContainer bgColor="rgba(0, 0, 0, 0) linear-gradient(rgb(167, 169, 246) 0%, rgb(198, 234, 250) 100%) repeat scroll 0% 0%">
        <Hero
          largeTitle={hero?.title}
          smallTitle={hero?.subtitle}
          ctaCopy={hero?.text}
          img={hero?.image?.childImageSharp}
          roundImage
        />
      </SectionContainer>
      <SectionContainer>
        <Heading
          fontSize={48}
          textTransform="uppercase"
          textAlign="center"
          mb={8}
          mt={12}
        >
          {features?.title}
        </Heading>
        <SimpleGrid
          gridTemplateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'}
          maxW="fit-content"
          mx="auto"
        >
          {features?.items?.map(item => (
            <Box key={item.title}>
              <Image src={item.image.publicURL} alt={item.title} />
            </Box>
          ))}
        </SimpleGrid>
      </SectionContainer>
      <SectionContainer bgColor="rgb(244, 242, 234)">
        <Box textAlign="center" mb={12} maxW="md" mx="auto">
          <Image
            src={partners.image.src.publicURL}
            alt={partners.image.title}
            display="inline-block"
            mb={4}
          />
          <Text>{partners.subtitle}</Text>
        </Box>
        <Flex mb={24} direction={isMobile ? 'column' : 'row'}>
          {partners?.items.map(item => (
            <Flex
              direction="column"
              alignItems="center"
              mx={8}
              mb={isMobile ? 12 : 0}
              key={item.partner}
            >
              <Image
                h={20}
                mb={4}
                src={item.image.publicURL}
                alt={item.partner}
              />
              <Text textAlign="center">
                {item.text}
                <br />
                <Link to={item.url} aria-label={item.partner}>
                  Learn More
                </Link>
              </Text>
            </Flex>
          ))}
        </Flex>
      </SectionContainer>
    </>
  )
}

export default WhoWeArePage

export const query = graphql`
  query WhoWeAreQuery {
    whoWeAreJson {
      hero {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 327, quality: 100) {
              srcSet
              aspectRatio
            }
          }
        }
      }
      features {
        title
        items {
          title
          image {
            publicURL
          }
        }
      }
      partners {
        image {
          src {
            publicURL
          }
          title
        }
        subtitle
        items {
          image {
            publicURL
          }
          partner
          text
          url
        }
      }
    }
  }
`
